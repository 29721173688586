﻿import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {User} from '../_models';
import { Send } from '../_models/send';
import {Router} from '@angular/router';
import { EndpointService } from 'app/services/endpoint.service';
let environment: any;
@Injectable({providedIn: 'root'})
export class DbService {
    constructor(private http: HttpClient, private router: Router,
        public endp: EndpointService) {
            environment = this.endp.endPoints;
    }

    private oSend: Send;

    call(obj: string, ojson: object) {
        return this.execute(obj, "C","", ojson, "");
    }

    select(obj: string, where: string,keyColunm = '') {
        return this.execute(obj, "S", where, {}, keyColunm);
    }

    insert(obj: string, ojson: object, keycolumn: string) {
        return this.execute(obj, "I", "", ojson, keycolumn);
    }

    update(obj: string, where: string, ojson: object) {
        if (where) {
            return this.execute(obj, "U", where,  ojson, "");
        } else {
            console.log({'error': 'update needs a where'})
        }
    }

    delete(obj: string, where: string) {
        if(where){
            return this.execute(obj, "D", where,  {}, "");
        }
        else
        {
            console.log({"error": "delete needs a where"})
        }
    }

    private execute(obj: string, exec: string, where: string, ojson: object, keycolumn: string) {
        //#toapinet
        //return this.http.post<any>(`${environment.apiUrl}/db/exec`, {"objecto":obj, "operacion":exec, "where":where, "json":ojson, "keycolumn":keycolumn})
        //return this.http.post<any>(`${environment.apiUrl}/access/db`, {'obj':obj, 'exec':exec, 'where':where, 'ojson':ojson, 'keycolumn':keycolumn})

        var oCurrentUser = JSON.parse(localStorage.getItem('currentUser'));


        let oModel: Send = new Send(obj, exec, where, JSON.stringify(ojson),keycolumn, oCurrentUser.token);
        //return this.http.post<any>(`${environment.endPoint}/access/db`,  oModel)
        return this.http.post<any>(`${environment.endPoint}`,  oModel)
            .pipe(map(item => {
                //#toapinet
                //return item;
                //return JSON.parse(item);

                var oResult = item;
                if(oResult.tokenok === undefined)
                {
                    //return oResult;
                    return this.IsString(item) ? JSON.parse(item): item;
                }
                else
                {
                    if(oResult.tokenok){
                        return oResult;
                    }
                    else
                    {
                        this.router.navigate(['/login']);
                    }
                }
                
            }));
    }
    
    private IsString(item){
        if (typeof item === 'string' || item instanceof String)
        {
            return true;
        }
        else
        {
            return false;
        }         
    }

    returnEndpoint() {
      return  environment;
    }
}