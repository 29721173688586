import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {AppSettings} from '../../app.settings';
import {Settings} from '../../app.settings.model';
import {first} from 'rxjs/operators';
import {AuthenticationService, DbService} from '../_services';
import {MatSnackBar, MatDialogRef, ErrorStateMatcher, MatDialog} from '@angular/material';
let environment: any;
import { AlertDialogComponent } from '../../shared/alert_dialog/alert_dialog.component';
import { EndpointService } from 'app/services/endpoint.service';

@Component({
    selector: 'app-change_password',
    templateUrl: './change_password.component.html',
    styleUrls: ['./change_password.component.scss'],
})
export class ChangePasswordComponent {
    public form: FormGroup;
    data: any;
    public settings: Settings;
    returnUrl: string;
    error = '';
    matcher = new MyErrorStateMatcher();
    currentUser: any = {};
    public message = {
        show: false,
        status: 'warn',
        text: 'test'
    };

    constructor(public appSettings: AppSettings,
                public fb: FormBuilder,
                private route: ActivatedRoute,
                public router: Router,
                public snackBar: MatSnackBar,
                private authenticationService: AuthenticationService,
                public dialogRef: MatDialogRef<ChangePasswordComponent>,
                public dialog: MatDialog,
                public endp: EndpointService,
                public db: DbService) {
        this.settings = this.appSettings.settings;
        this.form = this.fb.group({
            'password': [null, Validators.compose([Validators.required])],
            'newpassword': [null, Validators.compose([Validators.required])],
            'confirmpassword': [null]
        }, {validator: this.checkPasswords });
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        environment = this.endp.endPoints;
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let newpassword = group.get('newpassword').value;
        let confirmpassword = group.get('confirmpassword').value;

        return newpassword === confirmpassword ? null : { notSame: true }     
    }

    ngAfterViewInit() {
        this.settings.loadingSpinner = false;
    }

    public onSubmit(values: any): void {
        if (this.form.invalid) {
            return;
        }

        this.db.call('sys_change_pass', { 
            user: this.currentUser.name, 
            old_pass : this.form.value.password, 
            new_pass: this.form.value.newpassword }).subscribe(result => {
            if (result[0].sn_changed) {
                this.dialogRef.close();
                
                const dialogRef = this.dialog.open(AlertDialogComponent, {
                    data: result[0].message,
                    width: '600px',
                    disableClose: true
                });
                dialogRef.componentInstance.title = 'Cambio de Contraseña';
            } else {
                this.message = {
                    show: true,
                    status: 'warn',
                    text: result[0].message
                };
            }
        });
    }
}

// and then as mentioned in other answer, the mat-error only shows if a FormControl is invalid, so you need an error state matcher:
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}