import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppSettings} from '../../app.settings';
import {Settings} from '../../app.settings.model';
import {first} from 'rxjs/operators';
import {AuthenticationService, DbService} from '../_services';
import {MatSnackBar, MatDialogRef} from '@angular/material';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-multicompania',
    templateUrl: './multicompania.component.html',
    styleUrls: ['./multicompania.component.scss'],
})
export class MultiCompanyComponent implements OnInit {
    public settings: Settings;
    public companies: any = [];
    returnUrl: string;
    error = '';
    user_id = 0;
    showLoading: boolean = false;

    @Input() data: any = [];

    constructor(public appSettings: AppSettings,
                public fb: FormBuilder,
                private route: ActivatedRoute,
                public router: Router,
                public snackBar: MatSnackBar,
                private authenticationService: AuthenticationService,
                public dialogRef: MatDialogRef<MultiCompanyComponent>,
                public db: DbService) {
        this.settings = this.appSettings.settings;

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    ngOnInit() {
        if (this.data.length <= 0) {
            this.data = this.companies;
        }
    }


    ngAfterViewInit() {
        this.settings.loadingSpinner = false;
    }

    btnSetCurrentUser(id) {
        this.showLoading = true;
        this.db.call('sys_set_current_user_client',  {id_user: this.user_id, id_client: id }).subscribe(resultCurrentUser => {
            this.showLoading = false;
            this.dialogRef.close(id);
        });
    }
}