import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        //#toapinet
        //if (currentUser && currentUser.token) {
        if (currentUser) {
            //         //#toapinet
            // request = request.clone({
            //     setHeaders: {
            //         //#toapinet
            //         //Authorization: `Bearer ${currentUser.token}`
            //         //Authorization: `Bearer ${currentUser}`
            //     }
            // });
            if(!currentUser.login){
                request = request.clone({
                    setHeaders: {
                        Authorization: "Bearer " + currentUser.token
                    }
                });
            }
        }

        return next.handle(request);
    }
}