import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DomSanitizer,SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'report_dialog-dialog',
    templateUrl: 'report_dialog.component.html',
    styleUrls: ['./report_dialog.component.scss'],
})
export class ReportDialogComponent implements OnInit {
    public report: string = '';
    public url: string = '';
    public url_nc: string = '';
    public url_c: string = '';
    public url_pep: string = '';
    safe_url: SafeResourceUrl;
    safe_nc: SafeResourceUrl;
    safe_c: SafeResourceUrl;
    safe_pep: SafeResourceUrl;

    constructor(
        public dialogRef: MatDialogRef<ReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public sanitizer:DomSanitizer) {
    }

    ngOnInit() {
        if (this.url !== '') {
            this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        }

        if (this.url_nc !== '') {
            this.safe_nc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_nc);
        }

        if (this.url_c !== '') {
            this.safe_c = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_c);
        }

        if (this.url_pep !== '') {
            this.safe_pep = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_pep);
        }
    }
}
