import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import {PagesComponent} from './pages/pages.component';
import {BlankComponent} from './pages/blank/blank.component';
import {SearchComponent} from './pages/search/search.component';
import {NotFoundComponent} from './pages/errors/not-found/not-found.component';
import {ErrorComponent} from './pages/errors/error/error.component';
import {AuthGuard} from './security/_guards';
import { BuscadorListaComponent } from './pages/listas/buscador-lista.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, canActivate: [AuthGuard], children: [
            {
                path: '',
                loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule',
                data: {breadcrumb: 'Dashboard'}
            },
            {
                path: 'abms',
                loadChildren: 'app/pages/abms/abms.module#AbmsModule',
                data: {breadcrumb: 'Catalogos'}
            },
            {
                path: 'perfil_riesgo',
                loadChildren: 'app/pages/perfil_riesgo/perfil_riesgo.module#PerfilRiesgoModule',
                data: {breadcrumb: 'Perfil Riesgo'}
            },
            {
                path: 'configuracion',
                loadChildren: 'app/pages/configuracion/configuracion.module#ConfiguracionModule',
                data: {breadcrumb: 'Configuración'}
            },
            {
                path: 'reportes',
                loadChildren: 'app/pages/reportes/reportes.module#ReportesModule',
                data: {breadcrumb: 'Reportes'}
            },
            {path: 'users', loadChildren: 'app/pages/users/users.module#UsersModule', data: {breadcrumb: 'Users'}},
            {path: 'blank', component: BlankComponent, data: {breadcrumb: 'Blank page'}},
            {path: 'search', component: SearchComponent, data: {breadcrumb: 'Search'}},
            {
                path: 'listas', 
                loadChildren: 'app/pages/listas/buscador-lista.module#BuscadorListaModule',
                data: {breadcrumb: 'Listas'}
            },
            {
                path: 'compania',
                loadChildren: 'app/pages/compania/compania.module#CompaniaModule',
                data: {breadcrumb: 'Compañía'}
            },
            {
                path: 'monitoreo',
                loadChildren: 'app/pages/monitoreo/monitoreo.module#MonitoreoModule',
                data: {breadcrumb: 'Monitoreo'}
            },
        ]
    },
    {path: 'login', loadChildren: 'app/security/login/login.module#LoginModule'},
    {path: 'register', loadChildren: 'app/security/register/register.module#RegisterModule'},
    {path: 'recover_password', loadChildren: 'app/security/recover_password/recover_password.module#RecoverPasswordModule'},
    {path: 'activate_account/:token', loadChildren: 'app/security/activate_account/activate_account.module#ActivateAccountModule'},
    {path: 'error', component: ErrorComponent, data: {breadcrumb: 'Error'}},
    {path: '**', component: NotFoundComponent}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    // useHash: true
});
