import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings} from '../../../../app.settings';
import {Settings} from '../../../../app.settings.model';
import {MenuService} from '../menu.service';
import {MatMenuTrigger} from '@angular/material';
import { Menu } from '../menu.model';
import { DbService } from '../../../../security/_services';

@Component({
    selector: 'app-horizontal-menu',
    templateUrl: './horizontal-menu.component.html',
    styleUrls: ['./horizontal-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MenuService]
})
export class HorizontalMenuComponent implements OnInit {
    @Input('menuParentId') menuParentId;
    public menuItems: any = [];
    public settings: Settings;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    currentUser: any = {};

    constructor(public appSettings: AppSettings, public menuService: MenuService, public router: Router,
        public db: DbService) {
        this.settings = this.appSettings.settings;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.db.call('sys_sp_GetMenu',  {p_usuario: this.currentUser.name}).subscribe(result => {
            for (let item of result) {
                const menuItem = new Menu(item.id, item.title, item.router_link, item.href, item.icon, item.target, item.sub_menu, item.parent_id);
                this.menuItems.push(menuItem);
            }

            this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
        });
    }

    ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.settings.fixedHeader) {
                    let mainContent = document.getElementById('main-content');
                    if (mainContent) {
                        mainContent.scrollTop = 0;
                    }
                }
                else {
                    document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
                }
            }
        });
    }

}