﻿import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { EndpointService } from 'app/services/endpoint.service';
let environment: any;

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    constructor(private http: HttpClient,
        public endp: EndpointService) {
         this.http.get('environment.json').subscribe(response => {
            environment = response;
        });
    }
    login(model: any) {
        //#toapinet
        //return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, model)
       // return this.http.post<any>(`${environment.endPoint}/access/login`, model)
       return this.http.post<any>(`${environment.loginUrl}/login`, model)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}