import {Component} from '@angular/core';
import {AppSettings} from './app.settings';
import {Settings} from './app.settings.model';
import { EndpointService } from './services/endpoint.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public settings: Settings;

    constructor(
        public appSettings: AppSettings,
        public enpoint: EndpointService
        ) {
        this.settings = this.appSettings.settings;
    }
}
