import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'reason-dialog',
    templateUrl: 'reason_dialog.component.html',
    styleUrls: ['reason_dialog.component.scss'],
})
export class ReasonDialogComponent {
    public title: string = 'Alerta';

    constructor(
        public dialogRef: MatDialogRef<ReasonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
