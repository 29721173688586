import {Menu} from './menu.model';

export const MenuItems = [
    new Menu(1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu(2, 'Usuarios', '/users', null, 'supervisor_account', null, false, 0),
    new Menu(222, 'Listas', '/listas', null, 'list', null, false, 0),
    new Menu(202, 'Catalogos', null, null, 'computer', null, true, 0),
    // new Menu (203, 'Paises', '/abms/paises', null, 'keyboard', null, false, 202),
    new Menu(203, 'Países', '/abms/paises', null, 'view_module', null, false, 202),
    new Menu(223, 'Bancos', '/abms/bancos', null, 'view_module', null, false, 202),
    new Menu(204, 'Actividad Económica', '/abms/actividad_economica', null, 'view_module', null, false, 202),
    new Menu(205, 'Tipo Factor Riesgo', '/abms/tipo_factor_riesgo', null, 'view_module', null, false, 202),
    new Menu(206, 'Riesgo Periodicidad', '/abms/riesgo_ingreso_sem', null, 'view_module', null, false, 202),
    new Menu(207, 'Frecuencia Operaciones', '/abms/frec_oper_riesgo', null, 'view_module', null, false, 202),
    new Menu(208, 'Forma de Pago', '/abms/forma_pago_riesgo', null, 'view_module', null, false, 202),
    new Menu(209, 'Indicador Probabilidad', '/abms/indicador_probabilidad', null, 'view_module', null, false, 202),
    new Menu(210, 'Indicador Impacto', '/abms/indicador_impacto', null, 'view_module', null, false, 202),
    new Menu(211, 'Clientes', '/abms/cliente', null, 'view_module', null, false, 202),
    new Menu(212, 'Proveedores', '/abms/proveedor', null, 'view_module', null, false, 202),
    new Menu(213, 'Empleados', '/abms/empleado', null, 'view_module', null, false, 202),
    new Menu(221, 'Objetivo Control', '/abms/objetivo_control', null, 'view_module', null, false, 202),
    new Menu(3, 'Tipo Control', '/abms/tipo_control', null, 'view_module', null, false, 202),
    new Menu(214, 'Configuración', null, null, 'computer', null, true, 0),
    new Menu(215, 'Controles', '/configuracion/controles', null, 'view_module', null, false, 214),
    new Menu(216, 'Riesgo Territorio', '/configuracion/riesgo_territorio', null, 'view_module', null, false, 214),
    new Menu(217, 'Factor Riesgo', '/configuracion/factor_riesgo', null, 'view_module', null, false, 214),
   /* new Menu(218, 'Factor Riesgo PEP', '/configuracion/factor_riesgo_pep', null, 'view_module', null, false, 214),*/
    new Menu(219, 'Inventario Riesgo', '/configuracion/inventario_riesgo', null, 'view_module', null, false, 214),
    new Menu(220, 'Criterios Aplicables', '/configuracion/criterio_aplicable', null, 'view_module', null, false, 214),
    new Menu(20, 'Perfil Riesgo', null, null, 'dvr', null, true, 0),
    new Menu(21, 'Proveedores', '/blank', null, 'short_text', null, false, 20),
    new Menu(22, 'Empleados', '/blank', null, 'short_text', null, false, 20),
    /*new Menu(21, 'Cliente', '/perfil_riesgo/cliente', null, 'short_text', null, false, 20)*/,
    new Menu(30, 'Clientes', null, null, 'view_module', null, true, 0),
    new Menu(31, 'Basic', '/blank', null, 'view_column', null, false, 30),
    new Menu(50, 'Templates', '/schedule', null, 'event', null, false, 0),
    new Menu(40, 'Reportes', null, null, 'library_books', null, true, 0),
    new Menu(41, 'Perfil de Riesgo', '/reportes/perfil_riesgo', null, 'exit_to_app', null, false, 40),
];