﻿import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from '../_models';
import { EndpointService } from 'app/services/endpoint.service';
let environment: any;

@Injectable({providedIn: 'root'})
export class UserService {
    constructor(private http: HttpClient,
        public endp: EndpointService) {
        environment = this.endp.endPoints;
    }

    getAll() {
        //return this.http.get<User[]>(`${environment.apiUrl}/users`);
        return this.http.get<User[]>(`${environment.endPoint}/users`);
    }
}