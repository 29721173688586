// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    // reportUrl: 'http://localhost:53914/endpoint/Report',
    // accessUrl: 'http://localhost:53914/endpoint/access/',
    // loginUrl: 'http://localhost:53914/endpoint/access/login',
    // endPoint: 'http://localhost:53914/endpoint/access/db' 

   // reportUrl: 'http://104.238.124.171/endpointprod/Report',
   // accessUrl: 'http://104.238.124.171/endpointprod/access/',
   // loginUrl: 'http://104.238.124.171/endpointprod/access/login',
   // endPoint: 'http://104.238.124.171/endpointprod/access/db',     
   // logosUrl: 'http://104.238.124.171/EndPointprod/Images/Logos/' 

     reportUrl: 'http://104.238.124.171/EndPointprod/Report',
     accessUrl: 'http://104.238.124.171/EndPointprod/access/',
     loginUrl: 'http://104.238.124.171/EndPointprod/access/login',
     endPoint: 'http://104.238.124.171/EndPointprod/access/db',
     logosUrl: 'http://104.238.124.171/EndPointprod/Images/Logos/'   

     //reportUrl: 'http://pgtest.pro/EndPointprod/Report',
     //accessUrl: 'http://pgtest.pro/EndPointprod/access/',
     //loginUrl: 'http://pgtest.pro/EndPointprod/access/login',
     //endPoint: 'http://pgtest.pro/EndPointprod/access/db',
     //logosUrl: 'http://pgtest.pro/EndPointprod/Images/Logos/' 
};





