import {Component, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import { ChangePasswordComponent } from '../../../security/change_password/change_password.component';
import { MatDialog } from '@angular/material';
import { WebService } from '../../../services/app.services';
import { DbService } from 'app/security/_services';
//import { environment } from 'environments/environment';
let environment: any;
import { MultiCompanyComponent } from 'app/security/multicompania/multicompania.component';
import { Observable } from 'rxjs/Observable';
import { AppSettings } from 'app/app.settings';
import { Router } from '@angular/router';
import { EndpointService } from 'app/services/endpoint.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
    public userImage = '../assets/img/users/user.jpg';
    public ClientName: string;
    public UserName: string;
    public currentUser: any = {};
    public companies: any = [];
    @Output() clientEmit = new EventEmitter<string>();


    constructor(
        public appSettings: AppSettings,
        public dialog: MatDialog,
        private WebService: WebService,
        public router: Router,
        public db: DbService,
        public endp: EndpointService
        ) {
            environment = this.endp.endPoints;
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        const clientObservable = this.WebService.GetClientName();
        clientObservable.subscribe((data: any) => {
            this.ClientName = data.ClientName;
            this.UserName = data.UserName;
        });
    }

    showCambiarContrasena() {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            data: 'asd',
            width: '600px',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === null || result === undefined) {
                return;
            } else {
                if (result) {
                    return;
                }
            }
        });

    }

    showCambiarCompania() {
        this.db.call('sys_get_clients_group',  {user: this.currentUser.name }).subscribe(resultGroup => {

            for (let item of resultGroup) {
                item.logo_file = environment.logosUrl + item.logo_file;
            }

            const dialogRef = this.dialog.open(MultiCompanyComponent, {
                width: '1000px',
                disableClose: true
            });

            dialogRef.componentInstance.companies = resultGroup;

            dialogRef.afterClosed().subscribe(result => {
                this.appSettings.settings.loadingSpinner = true;
                if (result > 0) {
                    this.db.call('sys_set_current_user_client',  {id_user: this.currentUser.id, id_client: result }).subscribe(resultCurrentUser => {
                        const clientObservable = this.WebService.GetClientName();
                        clientObservable.subscribe((data: any) => {
                            this.clientEmit.emit(data.ClientName);
                            this.ClientName = data.ClientName;
                            this.UserName = data.UserName;

                            if (this.router.url === '/') {
                                window.location.reload();
                            } else {
                                this.appSettings.settings.loadingSpinner = false;
                                this.router.navigate(['/']);
                            }
                        });
                    });
                } else {
                    this.appSettings.settings.loadingSpinner = false;
                }
            });
        });
    }
}
