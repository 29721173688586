﻿export class Send {
    obj: string;
    exec: string;
    where: string;
    ojson: string;
    keycolumn:string;
    token:string;

    constructor(obj: string,exec: string,where: string, json: string, keycolumn:string, token:string){
        this.obj = obj;
        this.exec = exec;
        this.where = where;
        this.ojson = json;
        this.keycolumn = keycolumn;
        this.token = token;
    }
} 