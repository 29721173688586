
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import { EndpointService } from './endpoint.service';
let environment: any;
@Injectable()
export class WebService {
    private BASE_URL = '';

    constructor(private http: HttpClient,
        public endp: EndpointService) {
        environment = this.endp.endPoints;
    }

    getEndpoints(): Observable<any> {
        return this.http.get('environment.json');
    }

    GetClientName() {
        var oCurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.post(environment.accessUrl + '/GetUserData',{'sTotkenUser': oCurrentUser.token})
            .pipe(map(this.extractData));
    }

    GetExample(model: any): Observable<any> {
        // SOLO UTILIZAR EL AREA COMENTADA.
        const data: Observable<any> = of(model);

        /*const params = new URLSearchParams();
        const data = model;

        const options = {
            params: new HttpParams().set(
                'UserProfile',
                JSON.stringify(data))
        };

        return this.http.get(this.BASE_URL + 'Login',)
            .pipe(map(this.extractData));*/

        return data;
    }

    GetCatalogoPeriodicidad() {
        return this.http.get(this.BASE_URL + 'assets/data/catalogo_periodicidad.json')
            .pipe(map(this.extractData));
    }

    GetCatalogoPaises() {
        return this.http.get(this.BASE_URL + 'assets/data/catalogo_paises.json')
            .pipe(map(this.extractData));
    }

    GetCatalogoGeneros() {
        return this.http.get(this.BASE_URL + 'assets/data/generos.json')
            .pipe(map(this.extractData));
    }

    GetCatalogoTipoControles() {
        return this.http.get(this.BASE_URL + 'assets/data/catalogo_tipo_controles.json')
            .pipe(map(this.extractData));
    }

    GetCatalogoContinentes() {
        return this.http.get(this.BASE_URL + 'assets/data/catalogo_continente.json')
            .pipe(map(this.extractData));
    }

    GetCatalogoNivelesRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/catalogo_nivel_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetCatalogoTipoPersona() {
        return this.http.get(this.BASE_URL + 'assets/data/catalogo_tipo_persona.json')
            .pipe(map(this.extractData));
    }

    GetParametrizacionRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/parametrizacion_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetActividadEconomica() {
        return this.http.get(this.BASE_URL + 'assets/data/actividad_economica.json')
            .pipe(map(this.extractData));
    }

    GetFormaPagoRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/forma_pago_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetFrecuenciaOperacionRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/frec_oper_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetIndicadorImpacto() {
        return this.http.get(this.BASE_URL + 'assets/data/indicador_impacto.json')
            .pipe(map(this.extractData));
    }

    GetIndicadorProbabilidad() {
        return this.http.get(this.BASE_URL + 'assets/data/indicador_probabilidad.json')
            .pipe(map(this.extractData));
    }

    GetPaises() {
        return this.http.get(this.BASE_URL + 'assets/data/paises.json')
            .pipe(map(this.extractData));
    }

    GetRiesgoTerritorio() {
        return this.http.get(this.BASE_URL + 'assets/data/riesgo_territorio.json')
            .pipe(map(this.extractData));
    }

    GetFactorRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/factor_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetRiesgoIngresoSEM() {
        return this.http.get(this.BASE_URL + 'assets/data/riesgo_ingreso_sem.json')
            .pipe(map(this.extractData));
    }

    GetTipoFactorRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/tipo_factor_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetClientes() {
        return this.http.get(this.BASE_URL + 'assets/data/clientes.json')
            .pipe(map(this.extractData));
    }

    GetInventarioRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/inventario_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetProveedores() {
        return this.http.get(this.BASE_URL + 'assets/data/proveedores.json')
            .pipe(map(this.extractData));
    }

    GetEmpleados() {
        return this.http.get(this.BASE_URL + 'assets/data/empleados.json')
            .pipe(map(this.extractData));
    }

    GetDashboardPromedio() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_promedio.json')
            .pipe(map(this.extractData));
    }

    GetDashboardDistribucionRiesgo() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_distribucion_riesgo.json')
            .pipe(map(this.extractData));
    }

    GetDashboardRiesgosPorAnio() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_riesgo_por_anio.json')
            .pipe(map(this.extractData));
    }

    GetDashboardEntidad() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_entidad.json')
            .pipe(map(this.extractData));
    }

    GetDashboardJurisdiccion() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_jurisdiccion.json')
            .pipe(map(this.extractData));
    }

    GetDashboardClientes() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_entidad.json')
            .pipe(map(this.extractData));
    }

    GetDashboardProductos() {
        return this.http.get(this.BASE_URL + 'assets/data/dashboard_jurisdiccion.json')
    }

    GetPerfilRiesgoCliente() {
        return this.http.get(this.BASE_URL + 'assets/data/perfil_riesgo_cliente.json')
            .pipe(map(this.extractData));
    }

    GetPerfilRiesgoClienteInformacion() {
        return this.http.get(this.BASE_URL + 'assets/data/perfil_riesgo_cliente_informacion.json')
    }

    GetControles() {
        return this.http.get(this.BASE_URL + 'assets/data/controles.json')
            .pipe(map(this.extractData));
    }

    GetCriteriosAplicables() {
        return this.http.get(this.BASE_URL + 'assets/data/configuracion_criterios_aplicables.json')
            .pipe(map(this.extractData));
    }

    GetObjetivosControl() {
        return this.http.get(this.BASE_URL + 'assets/data/objetivo_control.json')
            .pipe(map(this.extractData));
    }

    GetTipoControl() {
        return this.http.get(this.BASE_URL + 'assets/data/tipo_control.json')
            .pipe(map(this.extractData));
    }

    GetFactorRiesgoPEP() {
        return this.http.get(this.BASE_URL + 'assets/data/factor_riesgo_pep.json')
            .pipe(map(this.extractData));
    }

    GetConoceTuClientePersonaNatural() {
        return this.http.get(this.BASE_URL + 'assets/data/conoce_tu_cliente_persona_natural.json')
            .pipe(map(this.extractData));
    }

    GetConoceTuClientePersonaJuridica() {
        return this.http.get(this.BASE_URL + 'assets/data/conoce_tu_cliente_persona_juridica.json')
            .pipe(map(this.extractData));
    }

    GetFormasPago() {
        return this.http.get(this.BASE_URL + 'assets/data/formas_pago.json')
            .pipe(map(this.extractData));
    }

    GetListas() {
        return this.http.get(this.BASE_URL + 'assets/data/listas.json')
            .pipe(map(this.extractData));
    }

    GetListasPersonas() {
        return this.http.get(this.BASE_URL + 'assets/data/lista_ofac.json')
            .pipe(map(this.extractData));
    }

    PostExample(model: any): Observable<any> {
        // SOLO UTILIZAR EL AREA COMENTADA.
        const data: Observable<any> = of(model);
        /*const params = new URLSearchParams();
        const data = {
            criteria: {
                param1: model.param1,
                param2: model.param2,
                param3: model.param3
            }
        };
        const headers = new Headers({'Content-Type': 'application/json'}); // ... Set content type to JSON
        // const options = new RequestOptions({headers: headers}); // Create a request option

        return this.http.post(this.BASE_URL + 'Register', data)
            .pipe(map(this.extractData));*/

        return data;
    }

    private extractData(res: any): any {
        const body = res;
        return body || {};
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
