
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class EndpointService {
    private endpoints = new BehaviorSubject<any>({});
    endpoints$ = this.endpoints.asObservable();

    constructor(private http: HttpClient) {
        this.http.get('environment.json').subscribe(response => {
            this.endpoints.next(response);
        });
    }

    get endPoints(): any {
        return this.endpoints.getValue();
    }

}
